import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";

import Header from "@components/Header/Header"
import PedidosHeader from "./BBs/PedidosHeader/PedidosHeader"

import { emptyResourceRedirect } from "@helpers/navigate"

const PedidosWrapper = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const empresas = useSelector((state) => (state as any).empresas).list;
  const proveedores = useSelector((state) => (state as any).proveedores).list;

  const [ready, setReady] = useState(false)

  useEffect(() => {
    const checkNoResources = !empresas.length || !proveedores.length
    emptyResourceRedirect({ check: checkNoResources, path: pathname, navigate })
    setReady(true)
  }, [])

  if (!ready) return (<></>)

  return (
    <>
      <Header />
      <PedidosHeader />
      <div className="center">
        <Outlet />
      </div>
    </>
  )
}

export default PedidosWrapper