import { useNavigate } from "react-router-dom"

import EsfirusButton from "@ui/Button/EsfirusButton"
import EsfirusIcon from "@ui/icons/EsfirusIcon"

import documentIcon from "@assets/images/icons/documentIcon.png"
import listIcon from "@assets/images/icons/listIcon.png"

import "./PedidosHeader.scss"

const PedidosHeader = () => {
  const navigate = useNavigate()

  return (
    <div className="ef-pedidos-header">
      <EsfirusButton
        color="secondary"
        startIcon={<EsfirusIcon path={documentIcon} />}
        onClick={() => navigate("/pedidos/nuevo")}
      >
        Nuevo pedido
      </EsfirusButton>
      <EsfirusButton
        color="primary"
        variant="outlined"
        startIcon={<EsfirusIcon path={listIcon} />}
        onClick={() => navigate("/pedidos")}
      >
        Listado de pedidos
      </EsfirusButton>
    </div >
  )
}

export default PedidosHeader