import Login from "@screens/Login/Login";
import {
  StyledEngineProvider,
  ThemeProvider as ThemeProvider2,
} from "@mui/material";
import theme from "./theme";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import GlobalSpinner from "@components/GlobalSpinner/GlobalSpinner";
import Home from "@screens/Home/Home";
import NewParte from "@screens/NewParte/NewParte";
import Report from "@screens/Report/Report";
import Pending from "@screens/Pending/Pending";
import Chat from "@screens/Chat/Chat";
import { Provider } from "react-redux";
import store from "./store/index";
import Splash from "@screens/Splash/Splash";
import Backoffice from "@screens/Backoffice/Backoffice";
import React, { useEffect } from "react";
import ResetPassword from "@screens/ResetPassword/ResetPassword";
import TrackHoras from "@screens/TrackHoras/TrackHoras";
import TrackHorasResumen from "@screens/TrackHorasResumen/TrackHorasResumen";
import themeBack from "@screens/Backoffice/theme/styles";

import i18n from "./languages/i18next";
import TrackHorasList from "@screens/TrackHorasList/TrackHorasList";
import Modules from "@screens/Modules/Modules";
import GeneralModalError from "@screens/GeneralModalError/GeneralModalError";
import PedidosWrapper from "@screens/Pedidos/PedidosWrapper";
import ListadoPedidos from "@screens/Pedidos/ListadoPedidos/ListadoPedidos";
import NuevoPedido from "@screens/Pedidos/NuevoPedido/NuevoPedido";
import PedidoIndividual from "@screens/Pedidos/PedidoIndividual/PedidoIndividual";
import useVersionCheck from "@services/hooks/useVersionCheck";

const { contexts, ThemeProvider } = require("@esfirus/n-backoffice");

const { AuthProvider, ModelUpsertProvider } = contexts;

const GlobalWrapperApp = () => {
  const version = useVersionCheck();

  return (
    <>
      <GeneralModalError />
      <Outlet />
      <div style={{ textAlign: "center", color: "gray", opacity: 0.4 }}>Vers. {version}</div>
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <GlobalWrapperApp />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/home",
        element: <Modules />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/auth/establecer-password/:token/:base",
        element: <ResetPassword />,
      },
      {
        path: "/splash",
        element: <Splash />,
      },
      {
        path: "/partes",
        element: <Home />,
      },
      {
        path: "/partes/nuevo",
        element: <NewParte />,
      },
      {
        path: "/partes/resumen",
        element: <Report />,
      },
      {
        path: "/partes/pendientes",
        element: <Pending />,
      },
      {
        path: "/chat",
        element: <Chat />,
      },
      {
        path: "/time-tracking",
        element: <TrackHoras />,
      },
      {
        path: "/time-report",
        element: <TrackHorasList />,
      },
      {
        path: "/time-month-report",
        element: <TrackHorasResumen />
      },
      {
        element: <PedidosWrapper />,
        children: [
          {
            path: "/pedidos",
            element: <ListadoPedidos />,
            children: [
              {
                path: "/pedidos/:id",
                element: <PedidoIndividual isEditing={false} />
              },
              // No permitimos editar el pedido de momento hasta nuevo aviso. Comment de Jira: https://esfirus.atlassian.net/browse/PARTRAB-295?focusedCommentId=32303
              // Permitimos editar, pero solo los adjuntos. https://esfirus.atlassian.net/browse/PARTRAB-566?focusedCommentId=33321
              {
                path: "/pedidos/:id/edit",
                element: <PedidoIndividual isEditing={true} />
              }
            ]
          },
          {
            path: "/pedidos/nuevo",
            element: <NuevoPedido />
          },
        ]
      },
    ]
  },
]);

const AppModule = () => {
  if (window.location.pathname.includes("backoffice")) {
    return (
      <AuthProvider i18n={i18n}>
        <ModelUpsertProvider>
          <ThemeProvider theme={themeBack}>
            <Backoffice />
          </ThemeProvider>
        </ModelUpsertProvider>
      </AuthProvider>
    );
  }
  return <RouterProvider router={router} fallbackElement={<GlobalSpinner />} />;
};

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider2 theme={theme}>
            <AppModule />
          </ThemeProvider2>
        </StyledEngineProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
